


























































import {Web} from "@/store/web";
import {User} from "@/store/user";
import {Auth} from "@/store/auth";
import {Core} from "@/store/core";
import {Report} from "@/store/report";
import { userRouter } from '@/router/user'
import {Component, Vue, Watch} from "vue-property-decorator";
import _ from 'lodash'
import IIT from '@/components/ReportAdmin/IIT.vue'
import EIT from '@/components/ReportAdmin/EIT.vue'
import OIT from '@/components/ReportAdmin/OIT.vue'
@Component({
  components: {
    IIT,EIT,OIT
  },
  computed: {}
})

export default class ReportPage extends Vue {
  private exTab:any = [0,1,2,3]
 private agencies:any = null;
  private years:any = null;
  private dialog:boolean = false
  private year:any = null
  private agency:any = null
  private yearIIT:any = null
  private yearEIT:any = null
  private yearOIT:any = null
  private binIIT:any = 0
  private binEIT:any = 0
  private binOIT:any = 0
  private rawIIT:any = null;
  private rawEIT:any = null;
  private rawOIT:any = null;
  async created() {
    await Web.switchLoad(true)
    await this.loadEnv();

    await Web.switchLoad(false)
      
  }

  async loadEnv(){
    this.agencies = await Core.getHttp(`/api/ita/v1/agency/`)
    this.years = await Core.getHttp(`/api/ita/v1/year/`)
  }

  async getDetail(agency:any){
    if(this.year){
      this.agency = agency
      this.yearIIT = await Report.getYearIIT(this.year)
      this.yearEIT = await Report.getYearEIT(this.year)
      this.yearOIT = await Report.getYearOIT(this.year)
      this.dialog = true;
    }else{
      alert('กรุณาเลือกปีงบประมาณ')
    }

  }
  async closeDialog(){
    this.dialog = false
  }

  async getIITScore(val:any){
      this.binIIT = val.score30
      this.rawIIT = val
  }

  async getEITScore(val:any){
    this.binEIT = val.score30
    this.rawEIT = val
  }
  async getOITData(value:any){
    this.binOIT = value.score60
    this.rawOIT = value
  }

  async storeData(){
    await this.storeReport();
    await this.storeReportDetail();
  }

  async storeReport(){
    let form = {
      year : this.year,
      agency : this.agency.id,
      iit : Number(this.rawIIT.score30),
      eit : Number(this.rawEIT.score30),
      oit : Number(this.rawOIT.score60),
      all : Number(this.rawIIT.score30) + Number(this.rawEIT.score30) + Number(this.rawOIT.score60),
    }
    await Core.postHttp(`/api/report/v1/reportall/`,form)
  }

  async storeReportDetail(){

      for (let i =0 ; i < this.rawIIT.list_data.length ; i++){
        let bin = this.rawIIT.list_data[i]

        let form = {
          year : this.year,
          agency : this.agency.id,
          name :  bin.name,
          score :  bin.score,
          order :  bin.order,
        }
        console.log('{DTATA}',form);
        await Core.postHttp(`/api/report/v1/reportdetail/`,form)
      }

    for (let i =0 ; i< this.rawEIT.list_data.length ; i++){
      let bin = this.rawEIT.list_data[i]
      let form = {
        year : this.year,
        agency : this.agency.id,
        name :  bin.name,
        score :  bin.score,
        order :  bin.order,
      }
      await Core.postHttp(`/api/report/v1/reportdetail/`,form)
    }

  }


  

}
