
















































































import {
  Component, Prop,
  Vue
} from 'vue-property-decorator';


import {
  Auth
} from '@/store/auth'
import {
  Core
} from '@/store/core'
import {
  User
} from '@/store/user'
import _ from 'lodash'

@Component({
  components: {

  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  }
})
export default class Home extends Vue {
  @Prop({default: null})
  public currentAgency!: any
  @Prop({default: null})
  public currentYear!: any
  
  private currentId: any | null = null
  private user: any = {}
  private year: any = []
  private rates: any = []
  private dialog: boolean = false
  private rate: any = {}
  private form: any = {}
  private formUpdate: any = null
  private rateDatas: any = []
  private rateStatus: any = []
  private response: boolean = false

  private scoreAll:number = 0
  private scoreArr:any = [];
  public async created() {

    await this.run()

  }
  result:any = []
  private async run() {
    let loader = await this.$loading.show()
    this.response = false;
    this.currentId = this.$route.query.id
    this.user = await User.getUser();
    this.year = await Core.getHttp(`/api/ita/v2/year/${this.currentYear.id}/`)
    this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.currentYear.id}/`)
    this.result = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.currentAgency.id}`)
    this.rateStatus = await Core.getHttp(`/api/ita/v1/ratestatus/`)
    await this.generateTable()
    await this.generateScore();
    this.response = true;
    await loader.hide()
  }

  private async generateTable() {
    for (let i = 0; i < this.rates.length; i++) {
      console.log(this.rates[i].id);
      let result: any = _.filter(this.result, {
        'rate': this.rates[i].id
      })
      this.rates[i].result = result
    }
  }

  public async openDialog(rate: any) {
    this.rate = rate
    this.form.rate = rate.id
    this.form.name = rate.name
    await this.loadRateData(rate);
    this.dialog = true;
  }

  public async closeDialog() {
    this.rate = null
    this.form = {}
    this.dialog = false;
  }
  resultResponse:boolean = false
  private async loadRateData(rate:any) {
    this.rate = await Core.getHttp(`/api/ita/v1/rate/${rate.id}/`)
    this.form.rate = rate.id
    this.form.name = rate.name
    this.rateDatas = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.currentAgency.id}&rate=${this.rate.id}`)
    console.log(this.rate)
    this.resultResponse = true;
  }

  private async getResultByUser(i: number) {
    return await Core.putHttp(`/api/ita/v2/rateresult/`, {
      "agency": this.currentId,
      "rate": this.rates[i].id
    })
  }

  private async updateData(data: any) {
    data.user = data.user.pk
    data.user_passing = data.user_passing.pk
    data.tester = this.user.pk
    let update = await Core.putHttp(`/api/ita/v1/rateresult/${data.id}/`, data)
    if (update.id) {
      this.form = {}
      await this.run()
      await this.loadRateData(this.rate)

    }
  }



  private openLink(url: string) {
    window.open(url, '_blank');
  }
  getTest(result:any){
    let res = _.filter(result,{'rate':4})
    return res.length;
  }

  getPassingTest(result:any){
    let res = _.filter(result,function(o) { return o.tester != null; })
    return res.length;
  }
  getScoreAll(result:any){
    let score = _.meanBy(result, (p:any) => p.score);
    return score
  }

  async generateScore(){
    let listView = [];
    for (let index = 0 ; index < this.rates.length ; index++){

      let score = _.meanBy(this.rates[index].result, (p:any) => p.score);
      listView.push({
        "name":this.rates[index].name,
        "score":score,
        "order":index
      })

    }
    let scoreTmp = _.meanBy(listView, (p:any) => p.score)
    let response = {
      "list":listView,
      "score_avg":scoreTmp,
      "score60":((scoreTmp/100)*60).toFixed(2)
    }
    console.log('[dd]',response)
    this.$emit('data',response)
  }
}
