































































































































































import {
  Component, Prop,
  Vue,
  Watch
} from 'vue-property-decorator';


import {
  Auth
} from '@/store/auth'
import {
  Core
} from '@/store/core'
import {
  User
} from '@/store/user'
import {
  Iit
} from '@/store/iit'
import _ from "lodash"
import {
  Web
} from '@/store/web'
@Component({
  components: {

  },
})
export default class Home extends Vue {
  @Prop({default: null})
  public currentAgency!: any
  @Prop({default: null})
  public currentYear!: any
  
  private user!: any;
  private yearData!: any;
  private agency: any = null

  private assessmentTab: number = 0
  private assessmentData!: any;

  private issueRaw: any = [];
  private issueData: any = null;
  private response: boolean = false;

  private userDone: number = 0
  private scoreAll : any = []
  private score100 :number = 0
  private score30 :number = 0

  public async created() {
    await Web.switchLoad(true)
    await this.run();
    await this.getAssessment();
    await this.getUserAnswer()
    await this.generateScore();
    await this.getAverage();
    await this.callback();
    await Web.switchLoad(false)
    this.response = true
  }
  private async callback(){
    let listView = [];

    for (let index = 0 ; index < this.assessmentData.length ; index++){
      let start = await Core.getHttp(`/api/iit/v2/answerissue-report/?agency=${this.currentAgency.id}&assessmentIssues=${this.assessmentData[index].id}`)
      let issueGroup = null
      if(start.length > 0){
        issueGroup = await Iit.groupIssueRaw(start, this.userDone, this.agency.count);

      }
      listView.push({
        head:this.assessmentData[index],
        detail:issueGroup
      })
    }
    let raw = []
    for (let j =0; j < listView.length; j++){
      let scoreOut = 0
      let score = listView[j].detail
      if(score ){
        for (let y = 0 ; y<  score.length; y++){
          scoreOut = _.meanBy(score[y].value, (p:any) => p.avg);
        }
      }
      raw.push({
        score:scoreOut,
        name:listView[j].head.name,
        order:listView[j].head.order,
      })
    }
    let response = {
      "list":listView,
      'list_data':raw,
      "score30":this.score30
    }
    // console.log(response);
    this.$emit('data', response)
  }
  private async run() {
    this.user = await User.getUser();
    this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.currentAgency.id}/`)
    this.yearData = await Core.getHttp(`/api/eit/v2/year/${this.currentYear.id}/`)

  }

  private async getAssessment() {
    this.assessmentData = await Core.getHttp(`/api/eit/v2/assessmentissues/?&year=${this.yearData.id}`)
    this.assessmentTab = 0
    await this.getRawIssue(this.assessmentData[0].id)
  }

  private async getUserAnswer() {
    let user = await Core.groupBy(this.issueRaw, 'user')
    this.userDone = user.length
  }

  @Watch('assessmentTab')
  private async switchTab(newIndex: number, oldIndex: number) {
    let assessmentData = this.assessmentData[newIndex]
    //console.log(newIndex, assessmentData.id)
    await this.getRawIssue(assessmentData.id)
    await this.generateScore()
  }

  private async getRawIssue(assignId: number) {
    this.issueRaw = await Core.getHttp(`/api/eit/v2/answerissueeit-report/?agency=${this.currentAgency.id}&assessmentIssues=${assignId}`)
  }

  private async generateScore() {
    if(this.issueRaw.length > 0){
      let issueGroup = await Iit.groupIssueRaw(this.issueRaw, this.userDone, this.agency.count);
      this.issueData = issueGroup
    }

  }
  toFixed2(num:number){
    return num.toFixed(2);
  }
  sumScore(arr:any){
    //console.log(arr);
    let numberArr = arr.length;
    let sumAvg =  _.sumBy(arr, 'avg');
    let score = (sumAvg / arr.length);
    return score.toFixed(2);
  }




  async getAverage(){
    let count = 0;
    let sumOutAvg = 0;
    let choice = 0;
    for (let i=0; i < this.assessmentData.length ; i++){
      let raw = await Core.getHttp(`/api/eit/v2/answerissueeit-report/?agency=${this.currentAgency.id}&assessmentIssues=${this.assessmentData[i].id}`)
      let issueGroup = await Iit.groupIssueRaw(raw, this.userDone, this.agency.count);
      for(let j=0; j < issueGroup.length; j++){
        let sumAvg =  this.sumScore(issueGroup[j].value)
        sumOutAvg += Number(sumAvg)
        choice++;

      }

    }
    this.score100 = Number((sumOutAvg/choice).toFixed(2))
    this.score30 =  Number(((sumOutAvg/choice)*0.4).toFixed(2))
    if(isNaN(this.score30)  ){
      this.score30 = 0.00
    }

  }

}
